import { Controller } from "stimulus";

// Define constants
const MIN_AGE = 18;
const MAX_AGE = 99;
const MIN_HEIGHT_CM = 122;
const MAX_HEIGHT_CM = 213;

export default class extends Controller {
  static targets = [
    "minVal",
    "maxVal",
    "minTooltip",
    "maxTooltip",
    "minInput",
    "maxInput",
    "minAgeField",
    "maxAgeField",
    "minHeightField",
    "maxHeightField"
  ];

  minGap = 0;
  heightMappings = [];
  sliderMinValue;
  sliderMaxValue;

  connect() {
    this.initializeValues();
    this.observeModal();

    // Listen for the reset event
    window.addEventListener('resetSlider', (event) => {
      this.resetSlider(event.detail);
    });
  }

  initializeValues() {
    if (this.hasMinAgeFieldTarget) {
      this.sliderMinValue = MIN_AGE;
      this.sliderMaxValue = MAX_AGE;
      this.minValTarget.value = this.minAgeFieldTarget.value || MIN_AGE;
      this.maxValTarget.value = this.maxAgeFieldTarget.value || MAX_AGE;
      this.minInputTarget.value = this.minAgeFieldTarget?.value || MIN_AGE;
      this.maxInputTarget.value = this.maxAgeFieldTarget?.value || MAX_AGE;
    } else if (this.hasMinHeightFieldTarget) {
      this.heightMappings = JSON.parse(this.data.get("heightMappings"));
      this.sliderMinValue = MIN_HEIGHT_CM;  
      this.sliderMaxValue = MAX_HEIGHT_CM;
      this.minValTarget.value = this.extractCmValue(this.minHeightFieldTarget?.value) || MIN_HEIGHT_CM;
      this.maxValTarget.value = this.extractCmValue(this.maxHeightFieldTarget?.value) || MAX_HEIGHT_CM;
      this.minInputTarget.value = this.extractCmValue(this.minHeightFieldTarget?.value) || MIN_HEIGHT_CM;
      this.maxInputTarget.value = this.extractCmValue(this.maxHeightFieldTarget?.value) || MAX_HEIGHT_CM;
    }
    this.updateTooltips();
  }

  extractCmValue(value) {
    if (value) {
      const match = this.heightMappings.find(mapping => mapping.string === value.trim());
      
      if (match) {
        return match.cm; 
      }
    }
    return null; 
  }
  

  getClosestValue(value) {
    const closest = this.heightMappings.reduce((prev, curr) => {
      return Math.abs(curr.cm - value) < Math.abs(prev.cm - value) ? curr : prev;
    });
    return closest.cm;
  }

  getStringForValue(value) {
    const mapping = this.heightMappings.find((item) => item.cm == value);
    return mapping ? mapping.string : '';
  }

  updateTooltips() {
    this.minTooltipTarget.innerHTML = `<span class="min-tooltip-number">${this.minValTarget.value}</span>`;
    this.maxTooltipTarget.innerHTML = `<span class="max-tooltip-number">${this.maxValTarget.value}</span>`;
    
  }

  resetSlider({ min, max, type }) {
    if (type === 'age' && this.hasMinAgeFieldTarget && this.hasMaxAgeFieldTarget) {
      this.minValTarget.value = min;
      this.maxValTarget.value = max;
    } else if (type === 'height' && this.hasMinHeightFieldTarget && this.hasMaxHeightFieldTarget) {
      this.minValTarget.value = min;
      this.maxValTarget.value = max;
    }
    this.updateTooltips();
    this.updateFields();
    this.setArea();
  }

  slideMin() {
    let gap = parseInt(this.maxValTarget.value) - parseInt(this.minValTarget.value);
    if (gap <= this.minGap) {
      this.minValTarget.value = parseInt(this.maxValTarget.value) - this.minGap;
    }
    if (this.hasMinHeightFieldTarget) {
      this.minValTarget.value = this.getClosestValue(this.minValTarget.value);
    }
    this.updateTooltips();
    this.minInputTarget.value = this.minValTarget.value;
    this.updateFields();
    this.setArea();
  }

  slideMax() {
    let gap = parseInt(this.maxValTarget.value) - parseInt(this.minValTarget.value);
    if (gap <= this.minGap) {
      this.maxValTarget.value = parseInt(this.minValTarget.value) + this.minGap;
    }
    if (this.hasMaxHeightFieldTarget) {
      this.maxValTarget.value = this.getClosestValue(this.maxValTarget.value);
    }
    this.updateTooltips();
    this.maxInputTarget.value = this.maxValTarget.value;
    this.updateFields();
    this.setArea();
  }

  setArea() {
    const thumbLeftPosition = (this.minValTarget.value - this.sliderMinValue) / (this.sliderMaxValue - this.sliderMinValue);
    const thumbRightPosition = (this.maxValTarget.value - this.sliderMinValue) / (this.sliderMaxValue - this.sliderMinValue);
    
    const spaceLeft = this.minValTarget.offsetWidth - this.minTooltipTarget.offsetWidth; 
    const spaceRight = this.maxValTarget.offsetWidth - this.maxTooltipTarget.offsetWidth; 
    
    this.minTooltipTarget.style.left = `${thumbLeftPosition * spaceLeft - 2.5}px`;
    this.maxTooltipTarget.style.left = `${thumbRightPosition * spaceRight + 2.5}px`;
  }

  setMinInput() {
    let minAge = parseInt(this.minInputTarget.value);
    if (minAge < this.sliderMinValue) {
      this.minInputTarget.value = this.sliderMinValue;
    }
    this.minValTarget.value = this.minInputTarget.value;
    this.slideMin();
  }

  setMaxInput() {
    let maxAge = parseFloat(this.maxInputTarget.value);
    if (maxAge > this.sliderMaxValue) {
      this.maxInputTarget.value = this.sliderMaxValue;
    }
    this.maxValTarget.value = this.maxInputTarget.value;
    this.slideMax();
  }

  updateFields() {
    if (this.hasMinAgeFieldTarget) {
      this.minAgeFieldTarget.value = this.minValTarget.value > MIN_AGE ? this.minValTarget.value : "";
      this.maxAgeFieldTarget.value = this.maxValTarget.value < MAX_AGE ? this.maxValTarget.value : "";
    } else if (this.hasMinHeightFieldTarget) {
      this.minHeightFieldTarget.value = this.minValTarget.value > MIN_HEIGHT_CM ? this.getStringForValue(this.minValTarget.value) : "";
      this.maxHeightFieldTarget.value = this.maxValTarget.value < MAX_HEIGHT_CM ? this.getStringForValue(this.maxValTarget.value) : "";
    }
  }

  observeModal() {
    const observer = new MutationObserver(() => {
      const modalDialog = document.querySelector('.modal-dialog');
      if (modalDialog && modalDialog.offsetWidth > 0) {
        this.setArea();
        observer.disconnect(); // Stop observing after the first run
      }
    });
    observer.observe(document.body, { childList: true, subtree: true });
  }
}
